<template>
  <div class="tieLockDetails">
    <div class="tieLockDetails-lt">
      <div class="content-box" v-for="item in dataListLeft" :key="item.key">
        <div class="title">{{item.label}}</div><span class="content">{{form[item.key]}}{{item.value}}</span>
      </div>
    </div>
    <div class="tieLockDetails-rt">
      <div class="content-box" v-for="item in dataListRight" :key="item.key">
        <div class="title">{{item.label}}</div><span class="content">{{form[item.key]}}{{item.value}}</span>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      let _this = this;
      return {
        dataListLeft: [{
            label: "门锁型号：",
            key: "typeStr",
            isCol50: true,
          },
          {
            label: "MAC地址：",
            key: "mac",
            isCol50: true,
          },
          {
            label: "门锁房间：",
            key: "roomName",
            isCol50: true,
          },
          {
            label: "设备端软件版本：",
            key: "firmwareVersion",
            isCol50: true,
          },
          {
            label: "绑锁人：",
            key: "bindOperateUser",
            isCol50: true,
          },
          {
            label: "绑锁人账号：",
            key: "bindOperateAccount",
            isCol50: true,
          },
          {
            label: "绑定时间：",
            key: "bindOperateTime",
            isCol50: true,
          },
        ],
        dataListRight: [{
            label: "最近一次开锁时间：",
            key: "lastOpenLockTime",
            isCol50: true,
          },
          {
            label: "门锁与房间绑定状态：",
            key: "bindRoomStateStr",
            isCol50: true,
          },
          {
            label: "解绑人：",
            key: "unBoundOperateUser",
            isCol50: true,
          },
          {
            label: "解绑人账号：",
            key: "unBoundOperateAccount",
            isCol50: true,
          },
          {
            label: "解绑时间：",
            key: "unBoundOperateTime",
            isCol50: true,
          },
        ],
        form: {}
      };
    },
    created() {
      console.log("tieLockDetails created!!");

    },
    activated() {
      console.log("tieLockDetails activated!!");

      let dto = {
        lockId: this.$route.params.id,
        mac: this.$route.params.mac,
        // pageNum: "1",
        // pageSize: "10",
      };
      this.post("/device-service/lockmanagment/getLockInfo", dto, {
        // isUseResponse: true
      }).then(data => {
        if (data.type == '1003') {
          data.typeStr = 'WF-C1';
        } else if (data.type == '1004') {
          data.typeStr = 'WF-C1Z';
        } else if (data.type == '1008') {
          data.typeStr = 'WF-X11MG';
        } else if (data.type == '1011') {
          data.typeStr = 'WF-C2Z';
        } else if (data.type == '1020') {
          data.typeStr = 'WF-C3Z';
        } else if (data.type == '1012') {
          data.typeStr = 'WF-S75';
        }else if (data.type == '1009') {
          data.typeStr = 'WF-S64G';
        } else if (data.type == '2003') {
          data.typeStr = 'WF-S53';
        } else if (data.type == '2002') {
          data.typeStr = 'WF-X4M';
        } else if (data.type == '1013') {
          data.typeStr = 'WF-W3';
        } else if (data.type == '1014') {
          data.typeStr = 'WF-X4';
        } else if (data.type == '1015') {
          data.typeStr = 'WF-S42';
        } else if (data.type == '1017') {
          data.typeStr = 'WF-S54';
        } else if (data.type == '1018') {
          data.typeStr = 'WF-S41';
        } else if (data.type == '1019') {
          data.typeStr = 'WF-S43';
        } else if (data.type == '1002') {
          data.typeStr = 'WF-S52G';
        } else if (data.type == '1007') {
          data.typeStr = 'WF-X11G';
        } else {
          data.typeStr = this.lockTypeArr[data.type];
        }
        data.bindRoomStateStr = ['已解绑', '已绑定'][data.bindRoomState];
        data.unBoundOperateUser = data.unBoundOperateUser ? data.unBoundOperateUser : "--";
        data.unBoundOperateAccount = data.unBoundOperateAccount ? data.unBoundOperateAccount : "--";
        data.unBoundOperateTime = data.unBoundOperateTime ? data.unBoundOperateTime : "--";
        this.$set(this, 'form', data);
        if (data.bindRoomState == 0) {
          this.dataListRight = [{
              label: "最近一次开锁时间：",
              key: "lastOpenLockTime",
              isCol50: true,
            },
            {
              label: "门锁与房间绑定状态：",
              key: "bindRoomStateStr",
              isCol50: true,
            },
            {
              label: "解绑人：",
              key: "unBoundOperateUser",
              isCol50: true,
            },
            {
              label: "解绑人账号：",
              key: "unBoundOperateAccount",
              isCol50: true,
            },
            {
              label: "解绑时间：",
              key: "unBoundOperateTime",
              isCol50: true,
            },
          ]
        } else {
          this.dataListRight = [{
              label: "最近一次开锁时间：",
              key: "lastOpenLockTime",
              isCol50: true,
            },
            {
              label: "门锁与房间绑定状态：",
              key: "bindRoomStateStr",
              isCol50: true,
            },
          ]
        }
      })
    },
    mounted() {

    },
    methods: {

    }
  };
</script>
<style lang="scss" scoped>
  .table-box {
    height: 620px;
  }

  .tieLockDetails {
    margin: auto;
    width: 1200px;
    display: flex;
    flex-wrap: wrap;

    .tieLockDetails-lt {
      width: 50%;
    }

    .tieLockDetails-rt {
      width: 50%;
    }

    .content-box {
      margin: 20px 0;
      font-size: 15px;

      .title {
        display: inline-block;
        padding-right: 40px;
        text-align: right;
        width: 200px;
        color: #303133;
        // font-weight: 700;
      }


      .content {
        color: #666;
      }
    }

    .editBox {
      display: inline-block;
      margin: auto;

      .editTitle {
        display: inline-block;
        padding-right: 10px;
        text-align: right;
        width: 101px;
        color: #606266
      }

      .editContent {
        margin: 10px;
      }

      .el-textarea {
        width: 53%;
      }

      .el-input {
        width: 53%;
      }
    }

    .tieLockDetails-check-title {
      padding-bottom: 10px;
      font-weight: 700;
    }

    .tieLockDetails-check-text {
      padding-bottom: 10px;
      display: flex;

      span {
        padding-right: 46px;
      }
    }

    .table-box {
      height: 400px;
      overflow-y: auto;
    }
  }
</style>