<template>
  <div class="table-box">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
       opt: {
        search: [],
        columns: [
          { label: "IC卡名称", key: "name", },
          { label: "IC卡编号", key: "cardNumber" },
          { label: "IC卡时效", key: "cardTimeEffectStr" },
          { label: "生效/时效时间", key: "takeEffectTimeStr",width: 380 },
          { label: "IC卡状态", key: "statusStr" },
          { label: "发卡人", key: "senderUsername" },
          {
            label: "发卡人角色",
            key: "roleStr"
          },
          {
            label: "发卡时间",
            key: "createTime"
          }
        ]
      },
      roomList: [],
      userInfo:{}
    };
  },
  created() {
    console.log("ICCardInformation created!!");
    this.userInfo = this.cache.getLS("userInfo");
    this.row = this.cache.get("lockInfo");
  },
  activated() {
    console.log("ICCardInformation activated!!");
    this.row = this.cache.get("lockInfo");
    // this.$refs.appList.onRefresh();
  },
  mounted(){
     console.log("ICCardInformation mounted!!");
  },
  methods: {
    onGet(opt) {
      let dto = {
        pageNo: opt.skip,
        pageSize: opt.limit,
        roleType: 0,
        roomId: this.row.roomId,
        type: 'card',
        bindId: this.$route.params.id
        // beginTime: opt.searchForm["date"] ? opt.searchForm["date"][0] : "",
        // endTime: opt.searchForm["date"] ? opt.searchForm["date"][1] : "",
        // unLockMethod: opt.searchForm["unLockMethod"]
        //   ? opt.searchForm["unLockMethod"]
        //   : null
      };
      // W51 传bindId 
      // if(this.$route.params.type && this.$route.params.type == 32) {
      //   dto.bindId = this.$route.params.id;
      // }else {
      //   dto.historyId  = this.$route.params.id; 
      // }
      if(this.$route.params.type) {
        let type = Number(this.$route.params.type)
        if([12,13,14,15,1011,25,1020].indexOf(type) != -1) {
          dto.historyId = this.$route.params.id
        }
      }
      
       if(this.userInfo && (this.userInfo["roleType"]=='2' || this.userInfo["roleType"]=='6')){
        dto.plainUserName=this.userInfo["phoneNum"];
        dto.userId=this.userInfo["userId"];
      }
      this.post("landlord-service/house/v1/device/additive/history/page", dto, {
        isUseResponse: true
      }).then(res => {
         res = JSON.parse(JSON.stringify(res).replace(/list/g, "data"));
        if (res.data.data.data) {
          for (let i = 0; i < res.data.data.data.length; i++) {
            let item = res.data.data.data[i];
            let _startDate = item.beginTime;
            let _endDate = item.endTime;
            if(moment(_startDate).add(49, 'years').isBefore(_endDate) || item.endTime == 0 || item.endTime == null){
                item.cardTimeEffectStr = '永久';
                item.takeEffectTimeStr = '--'
            }else{
                item.cardTimeEffectStr = '限时';
                item.takeEffectTimeStr = this.format(_startDate)+' ~ '+this.format(_endDate);
            }
            if (item.attachment) { 
              item.attachment = JSON.parse(item.attachment)
              item.cardNumber = item.attachment.cardNumber||'--';
              if(item.attachment.status == 1) {
                item.statusStr = '正常'
              }else {
                item.statusStr = '失效'
              }
              item.roleStr=['','系统管理员','房东','工程人员','财务','房客','房管员','','公安'][item.attachment.roleId]
            }
            // if(this.$route.params.type) {
            //   item.cardNumber = item.additiveId ? item.additiveId : '--';
            //   item.statusStr = ['正常','停用'][item.state];
            //   item.roleStr = item.roleName ? item.roleName : '--';
            // }
            // else {
            //   if (item.attachment) {
            //     item.attachment = JSON.parse(item.attachment)
            //     item.cardNumber = item.attachment.cardNumber?item.attachment.cardNumber:item.attachment.cardStrNumber;
            //     item.statusStr=['正常','已失效','待生效','添加中','添加失败','修改中','修改失败','删除中','删除失败'][item.attachment.status-1];
            //     if(item.attachment.status == 1 || item.attachment.status == 2){
            //       let timeout = moment().format('x') > moment(item.endTime).format('x') ? 0 : 1
            //       item.statusStr = ['已失效','有效'][timeout]
            //     }
            //     item.roleStr=['','系统管理员','房东','工程人员','财务','房客','房管员','','公安'][item.attachment.roleId]
            //   }
            // }
          }
        }else {
          res.data.data.data = []
        }
        opt.cb(res.data.data);
      });
    },
  }
};
</script>
<style lang="scss">
.table-box{
  height: 620px;
}
</style>